import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

export const pageQuery = graphql`
  query MaterialQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
		excerpt(pruneLength: 140)
      frontmatter {
        title
		abstract
		content_list {
			headline
			lesson_list {
				lesson_title
				list {
					title
					file {
						relativePath
					}
					notice
				}
			}
		}
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 200, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
const MaterialPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""

	return (
		<Layout className="page">
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<div className="wrapper">
				{Image ? (
          		  <Img 
          		    fluid={Image} 
          		    alt={frontmatter.title + ' - Featured image'}
          		    className="top-page-image"
					fadeIn={false}
          		  />
          		) : ""}
				<h1>{frontmatter.title}</h1>
				<div className="material-container">
				<Content source={frontmatter.abstract} />
				{frontmatter.content_list.map((list, index) => (
					<div>
						<h3>{list.headline}</h3>
						<table>
							<tbody>
								{list.lesson_list.map((lesson, index) => (
									<tr>
										<td>{lesson.lesson_title}</td>
										<td>
											{lesson.list.map((list, index) => (
												<div>
													<a href={`/assets/${list.file.relativePath}`}>{list.title}</a>
													{list.notice ? (
														<span className="tag tag-primary">{list.notice}</span>
													) : ""}
												</div>
											))}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				))}
				</div>

				<article dangerouslySetInnerHTML={{ __html: html }} className="material-container"/>
			</div>
		</Layout>
	)
}

export default MaterialPage
